import './CalculateSplitForm.css';
import {Card} from "react-bootstrap";

function CalculateMatchResult({matchResult}) {

  return (
      <Card.Body>
        Person Two needs to pay: {matchResult.matchAmount}
        <br/>
        <br/>
        For a total of: {matchResult.total}
      </Card.Body>
  )
}

export default CalculateMatchResult;