import './CalculateSplitForm.css';
import {Card} from "react-bootstrap";

function CalculateSaveResult({saveResult}) {

  return (
      <Card.Body>
        Person One needs to pay: {saveResult.personOneSplit} per month
        <br/>
        <br/>
        Person Two needs to pay: {saveResult.personTwoSplit} per month
      </Card.Body>
  )
}

export default CalculateSaveResult;