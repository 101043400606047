import './CalculateSplitForm.css';
import {Card} from "react-bootstrap";

function CalculateSplitResult({splits}) {

  return (
      <Card.Body>
        Person One needs to pay: {splits.personOneSplit}
        <br/>
        <br/>
        Person Two needs to pay: {splits.personTwoSplit}
      </Card.Body>
  )
}

export default CalculateSplitResult;